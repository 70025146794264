import React, { useState } from 'react';
import { Button, Dropdown, Form, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ChevronDown } from 'react-bootstrap-icons';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../App.jsx';
import { toast } from 'react-toastify';
import { useTheme } from '../contexts/ThemeContext';
import { format } from 'date-fns';

function StatusControlNav({ 
    employeeId, 
    currentUserId, 
    isAdmin, 
    employeeName, 
    currentStatus,
    department,
    profilePhotoUrl,
    lastUpdated,
    comment
}) {
    const [note, setNote] = useState('');
    const [show, setShow] = useState(false);
    const { isDarkMode } = useTheme();

    // console.log('StatusControlNav props:', {
    //     employeeId,
    //     currentUserId,
    //     isAdmin,
    //     employeeName,
    //     currentStatus,
    //     department,
    //     profilePhotoUrl
    // });

    const handleStatusChange = async (newStatus) => {
        try {
            if (employeeId === currentUserId || isAdmin) {
                console.log('Starting status update:', {
                    employeeId,
                    currentUserId,
                    newStatus,
                    note
                });

                const employeeRef = doc(db, 'Employees', employeeId);
                
                await updateDoc(employeeRef, {
                    Status: newStatus,
                    Comment: note,
                    LastUpdated: serverTimestamp()
                });

                console.log('Status update completed');

                setNote('');
                setShow(false);
                toast.success('Status updated successfully');
            } else {
                toast.error('You can only update your own status');
            }
        } catch (error) {
            console.error('Error updating status:', error);
            toast.error('Failed to update status');
        }
    };

    const getStatusVariant = (status) => {
        const variants = {
            'In': 'success',
            'Out': 'danger',
            'Lunch': 'warning',
            'Meeting': 'danger',
            'Vacation': 'danger'
        };
        return variants[status] || 'secondary';
    };

    const getStatusInfo = () => {
        let timeInfo = 'No update time available';
        if (lastUpdated?.toDate) {
            const date = lastUpdated.toDate();
            const today = new Date();
            if (date.toDateString() === today.toDateString()) {
                timeInfo = `Today, ${format(date, 'h:mm a')}`;
            } else {
                timeInfo = format(date, 'PP, h:mm a');
            }
        }
        return { timeInfo, comment };
    };

    return (
        <div className="d-flex align-items-center justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end w-100">
            {/* Profile Image and Info */}
            <div className="d-flex align-items-center me-2">
                {profilePhotoUrl ? (
                    <img
                        src={profilePhotoUrl}
                        alt=""
                        style={{
                            width: '40px',
                            height: '40px',
                            borderRadius: '50%',
                            marginRight: '15px'
                        }}
                    />
                ) : (
                    <div
                        style={{
                            width: '40px',
                            height: '40px',
                            borderRadius: '50%',
                            backgroundColor: '#e0e0e0',
                            marginRight: '15px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        {employeeName ? employeeName.charAt(0) : '?'}
                    </div>
                )}
                <div>
                    <div className={`${isDarkMode ? 'text-body-emphasis fw-bold' : 'text-light fw-bold'} h4 my-0`}>
                        {employeeName || 'Loading...'}
                    </div>
                    <div className={`${isDarkMode ? 'text-body' : 'text-light'} small`}>
                        {department || 'Loading...'}
                    </div>
                </div>
            </div>
            <div className="vr me-2 bg-dark-subtle my-1"></div>
            {/* Status Badge Dropdown */}
            {currentStatus && (
                <Dropdown align="end" show={show} onToggle={(nextShow) => setShow(nextShow)}>
                    <Dropdown.Toggle 
                        as="div" 
                        id="status-dropdown"
                        className="d-inline-block h3"
                        style={{ cursor: 'pointer' }}
                    >
                        <Badge 
                            bg={getStatusVariant(currentStatus)} 
                            className={`${currentStatus === 'Lunch' ? 'text-primary' : ''} d-flex align-items-center gap-1 status-badge`}
                        >
                            {currentStatus}
                            <ChevronDown size={12} />
                        </Badge>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="p-3 shadow-sm mt-1 animate slideIn" style={{ minWidth: '250px' }}>
                        <div className="mb-3 pb-3 border-bottom">
                            <div className="d-flex align-items-center gap-2">
                                <div class="fs-5">Current Status: </div>
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        <Tooltip className="custom-tooltip">
                                            Last updated: {getStatusInfo().timeInfo}
                                        </Tooltip>
                                    }
                                >
                                    <Badge bg={getStatusVariant(currentStatus)} className="fs-5">
                                        {currentStatus}
                                    </Badge>
                                </OverlayTrigger>
                            </div>
                            {comment && (
                                <div className="mt-1 text-muted small">
                                    {comment}
                                </div>
                            )}
                        </div>
                        <h4 className="mt-0">Change your status</h4>
                        <div className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Add a comment (optional)"
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                                className="mb-2"
                            />
                        </div>
                        <div className="d-grid gap-2" id="status-dropdown-buttons">
                            <Button variant="success" onClick={() => handleStatusChange('In')}>In</Button>
                            <Button variant="danger" onClick={() => handleStatusChange('Out')}>Out</Button>
                            <Button variant="warning" onClick={() => handleStatusChange('Lunch')}>Lunch</Button>
                            <Button variant="danger" onClick={() => handleStatusChange('Meeting')}>Meeting</Button>
                            <Button variant="danger" onClick={() => handleStatusChange('Vacation')}>Vacation</Button>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </div>
    );
}

export default StatusControlNav; 